import _ from 'lodash';
import shortid from 'shortid';
import actionComposer from 'utilities/actionComposer';
import { queryContentStackByType } from 'utilities/contentStack';
import { formatOptions, defaultYieldOptions, ERRORS } from './constants';
import { sequencesArrayToWells, sequencesArrayToTubes } from './utilities';
import {
  api_AddToBuckCart,
  api_GetOrderedItem,
  api_UpdateOrderedItem
} from './api';

export const GET_SGRNA_STACK = actionComposer('GET_SGRNA_STACK');
export const getSGRNAStack = type => ({
  type: GET_SGRNA_STACK.type,
  payload: queryContentStackByType(type, {
    includedReferences: ['benefits', 'add_ons']
  })
});

export const ADD_SGRNA_PROJECT_TO_CART = actionComposer(
  'ADD_SGRNA_PROJECT_TO_CART'
);
export const addProjectToCart = project => ({
  type: ADD_SGRNA_PROJECT_TO_CART.type,
  payload: api_AddToBuckCart(project)
});

export const GET_SGRNA_CART_PROJECT = actionComposer('GET_SGRNA_CART_PROJECT');
export const getCartProject = id => ({
  type: GET_SGRNA_CART_PROJECT.type,
  payload: api_GetOrderedItem(id)
});

export const UPDATE_SGRNA_CART_PROJECT = actionComposer(
  'UPDATE_SGRNA_CART_PROJECT'
);
export const updateCartProject = (id, project) => ({
  type: UPDATE_SGRNA_CART_PROJECT.type,
  payload: api_UpdateOrderedItem(id, project)
});

export const CLEAR_SGRNA_CART_ERRORS = 'CLEAR_SGRNA_CART_ERRORS';
export const clearCartErrors = () => {
  return {
    type: CLEAR_SGRNA_CART_ERRORS
  };
};

export const SET_SGRNA_PROJECT_DETAILS = 'SET_SGRNA_PROJECT_DETAILS';
export const setProjectDetails = (format, productSlug, genome, cellLine) => {
  return {
    type: SET_SGRNA_PROJECT_DETAILS,
    newDetails: {
      format: _.find(formatOptions[productSlug], ['slug', format]),
      productSlug: productSlug,
      genome: genome,
      cellLine: cellLine
    }
  };
};

export const UPDATE_SGRNA_PROJECT_DETAILS = 'UPDATE_SGRNA_PROJECT_DETAILS';
export const updateProjectDetails = newDetails => {
  return {
    type: UPDATE_SGRNA_PROJECT_DETAILS,
    newDetails: newDetails
  };
};

export const SET_SGRNA_ACTIVE_PLATE = 'SET_SGRNA_ACTIVE_PLATE';
export const setActivePlate = plateIdx => {
  return {
    type: SET_SGRNA_ACTIVE_PLATE,
    plateIdx: plateIdx
  };
};

export const SET_SGRNA_ADD_DIALOG_METHOD = 'SET_SGRNA_ADD_DIALOG_METHOD';
export const setAddDialogMethod = value => {
  return {
    type: SET_SGRNA_ADD_DIALOG_METHOD,
    value: value
  };
};

export const SET_SGRNA_PLATE_DIALOG_METHOD = 'SET_SGRNA_PLATE_DIALOG_METHOD';
export const setPlateDialogMethod = (value, plateIdx) => {
  return {
    type: SET_SGRNA_PLATE_DIALOG_METHOD,
    value: value,
    plateIdx: plateIdx
  };
};

export const RESET_SGRNA_PROJECT = 'RESET_SGRNA_PROJECT';
export const resetProject = () => {
  return {
    type: RESET_SGRNA_PROJECT
  };
};

export const ADD_SGRNA_EMPTY_PLATE = 'ADD_SGRNA_EMPTY_PLATE';
export const addEmptyPlate = (project, plateType, plateName) => {
  //Generate an empty array of the appropriate size and fill it with empty well objects
  const wellsArray = _.range(0, plateType.wellCount)
    .fill({
      name: '',
      sequence: '',
      id: null,
      errors: [],
      warnings: []
    })
    //Wells need a unique id for drag and drop to work
    .map((w, i) => ({ ...w, id: `well-${shortid.generate()}` }));
  return {
    type: ADD_SGRNA_EMPTY_PLATE,
    plate: {
      //Plates need a unique id for tabs to work
      id: `plate-${shortid.generate()}`,
      name: plateName,
      new: true,
      loading: false,
      wells: wellsArray,
      yield: defaultYieldOptions[project.productSlug].plates,
      modifications: true
    }
  };
};

export const ADD_SGRNA_SEQUENCES_TO_PLATE = 'ADD_SGRNA_SEQUENCES_TO_PLATE';
export const addSequencesToPlate = (
  plateIdx,
  sequences,
  plateName,
  project
) => {
  return {
    type: ADD_SGRNA_SEQUENCES_TO_PLATE,
    meta: {
      plateIdx: plateIdx,
      plateName:
        !plateName || plateName.startsWith('Sheet')
          ? `Plate ${plateIdx + 1}`
          : plateName
    },
    payload: sequencesArrayToWells(sequences, project)
  };
};

export const DELETE_SGRNA_PLATE = 'DELETE_SGRNA_PLATE';
export const deletePlate = plateIdx => {
  return {
    type: DELETE_SGRNA_PLATE,
    plateIdx: plateIdx
  };
};

export const SET_SGRNA_PLATE_NAME = 'SET_SGRNA_PLATE_NAME';
export const setPlateName = (plateIdx, value) => {
  return {
    type: SET_SGRNA_PLATE_NAME,
    plateIdx: plateIdx,
    value: value
  };
};

export const SET_SGRNA_PLATE_YIELD = 'SET_SGRNA_PLATE_YIELD';
export const setPlateYield = (plateIdx, value) => {
  return {
    type: SET_SGRNA_PLATE_YIELD,
    plateIdx: plateIdx,
    value: value
  };
};

export const SET_SGRNA_PLATE_MODS = 'SET_SGRNA_PLATE_MODS';
export const setPlateMods = (plateIdx, value) => {
  return {
    type: SET_SGRNA_PLATE_MODS,
    plateIdx: plateIdx,
    value: value
  };
};

export const SET_SGRNA_PLATE_NEW = 'SET_SGRNA_PLATE_NEW';
export const setPlateNew = (plateIdx, value) => {
  return {
    type: SET_SGRNA_PLATE_NEW,
    plateIdx: plateIdx,
    value: value
  };
};

export const UPDATE_SGRNA_PLATE_WELLS = 'UPDATE_SGRNA_PLATE_WELLS';
export const updatePlateWells = (plateIdx, wells) => {
  return {
    type: UPDATE_SGRNA_PLATE_WELLS,
    plateIdx: plateIdx,
    newWells: wells
  };
};

export const UPDATE_SGRNA_WELL = 'UPDATE_SGRNA_WELL';
export const updateWell = (plateIdx, wellIdx, well) => {
  return {
    type: UPDATE_SGRNA_WELL,
    plateIdx: plateIdx,
    wellIdx: wellIdx,
    newWell: well
  };
};

export const ADD_SGRNA_EMPTY_TUBE = 'ADD_SGRNA_EMPTY_TUBE';
export const addEmptyTube = project => {
  return {
    type: ADD_SGRNA_EMPTY_TUBE,
    tube: {
      id: `tube-${shortid.generate()}`,
      name: '',
      sequence: '',
      errors: [ERRORS.NAME_INVALID, ERRORS.SEQUENCE_INVALID],
      yield: defaultYieldOptions[project.productSlug].tubes,
      modifications: true
    }
  };
};

export const UPDATE_SGRNA_TUBE = 'UPDATE_SGRNA_TUBE';
export const updateTube = (tubeIdx, tube) => {
  return {
    type: UPDATE_SGRNA_TUBE,
    tubeIdx: tubeIdx,
    newTube: tube
  };
};

export const ADD_SGRNA_SEQUENCES_TO_TUBES = 'ADD_SGRNA_SEQUENCES_TO_TUBES';
export const addSequencesToTubes = (project, sequences) => {
  return {
    type: ADD_SGRNA_SEQUENCES_TO_TUBES,
    sequences: sequencesArrayToTubes(project, sequences)
  };
};

export const DELETE_SGRNA_TUBE = 'DELETE_SGRNA_TUBE';
export const deleteTube = tubeIdx => {
  return {
    type: DELETE_SGRNA_TUBE,
    tubeIdx: tubeIdx
  };
};

export const UPDATE_ADD_ONS = 'UPDATE_ADD_ONS';
export const updateAddOns = (addOn, quantity) => {
  return {
    type: UPDATE_ADD_ONS,
    newQuantity: quantity,
    newAddOn: addOn
  };
};
