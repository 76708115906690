import { createMuiTheme } from '@material-ui/core/styles';
import { responsiveTextConfig } from 'utilities/style';
import _ from 'lodash';

// See https://material-ui-next.com/customization/default-theme/ for available options
const defaultTheme = createMuiTheme();

const fonts = {
  headingFamily: ['"runda"', 'sans-serif'].join(','),
  bodyFamily: ['"open-sans"', 'sans-serif'].join(','),
  monoFamily: ['"roboto-mono"', 'sans-serif'].join(',')
};

const palette = {
  primary: {
    main: '#00151a',
    dark: '#000000',
    contrastText: '#fff'
  },
  secondary: {
    main: '#243b40',
    dark: '#001f26',
    contrastText: '#fff'
  },
  grey: {
    50: '#f4f9fa',
    100: '#e0e7e9',
    200: '#c2cacc',
    300: '#a3adb0',
    400: '#859093',
    500: '#667377',
    600: '#48565a',
    700: '#243b40',
    800: '#001f26',
    900: '#00151a'
  },
  gradients: {
    main: '45deg, #20d340 0%,#00d1ed 100%',
    kits: '45deg, #20d340 0%,#10D297 100%',
    cells: '45deg, #10D297 0%,#00D1ED 100%'
  },
  text: {
    primary: '#262626',
    secondary: 'rgba(0, 0, 0, 0.65)',
    disabled: 'rgba(0, 0, 0, 0.25)',
    hint: 'rgba(0, 0, 0, 0.65)',
    light: 'rgba(0, 0, 0, 0.65)'
  },
  error: {
    light: '#e57373',
    main: '#b71C1C',
    dark: '#b71C1C',
    contrastText: '#fff'
  },
  background: {
    default: '#fff'
  },
  status: {
    info: '#1EADF2',
    error: '#b71C1C',
    success: '#20D340',
    warning: '#F07700',
    muted: '#48565a'
  },
  action: {
    hoverOpacity: 0.03,
    hover: 'rgba(0,0,0,.03)',
    selected: 'rgba(0,0,0,.066)'
  }
};

const darkPalette = _.cloneDeep(palette);

darkPalette.type = 'dark';

darkPalette.primary = {
  main: '#fff',
  dark: '#e0e7e9',
  contrastText: '#00151a'
};
darkPalette.text = {
  primary: '#fff',
  secondary: 'rgba(255, 255, 255, 0.65)',
  disabled: 'rgba(255, 255, 255, 0.38)',
  hint: 'rgba(255, 255, 255, 0.38)'
};
darkPalette.background = {
  default: '#00151a',
  paper: '#243B40',
  particles: 'rgba(0, 31, 38, 0.75)'
};
darkPalette.divider = 'rgba(255, 255, 255, 0.12)';
darkPalette.action = {
  hoverOpacity: 0.03,
  hover: 'rgba(255,255,255,.03)',
  selected: 'rgba(255,255,255,.066)'
};

const typography = {
  fontFamily: fonts.headingFamily,
  display4: {
    color: palette.text.primary,
    fontWeight: 700,
    letterSpacing: '-0.03em',
    ...responsiveTextConfig(48, 64)
  },
  display3: {
    color: palette.text.primary,
    fontWeight: 700,
    ...responsiveTextConfig(32, 48)
  },
  display2: {
    color: palette.text.primary,
    fontWeight: 700,
    ...responsiveTextConfig(24, 32)
  },
  display1: {
    fontFamily: fonts.monoFamily,
    fontSize: '.75rem',
    fontWeight: 400,
    textTransform: 'uppercase',
    letterSpacing: '.15em'
  },
  headline: {
    fontWeight: 700,
    ...responsiveTextConfig(18, 20)
  },
  subheading: {
    fontSize: '.875rem',
    fontWeight: 500
  },

  h1: {
    color: palette.text.primary,
    fontWeight: 700,
    letterSpacing: '-0.03em',
    ...responsiveTextConfig(48, 64)
  },
  h2: {
    color: palette.text.primary,
    fontWeight: 700,
    ...responsiveTextConfig(32, 48)
  },
  h3: {
    color: palette.text.primary,
    fontWeight: 700,
    ...responsiveTextConfig(24, 32)
  },
  h4: {
    color: palette.text.primary,
    fontWeight: 700,
    ...responsiveTextConfig(20, 24, 0.4)
  },
  h5: {
    fontWeight: 700,
    ...responsiveTextConfig(18, 20, 0.4)
  },
  h6: {
    fontWeight: 700,
    ...responsiveTextConfig(16, 18, 0.3)
  },
  subtitle1: {
    fontWeight: 500,
    ...responsiveTextConfig(16, 18)
  },
  overline: {
    fontFamily: fonts.monoFamily,
    fontSize: '.75rem',
    lineHeight: '1rem',
    fontWeight: 400,
    textTransform: 'uppercase',
    letterSpacing: '.15em'
  },
  body2: {
    fontSize: '1rem',
    fontFamily: fonts.bodyFamily
  },
  body1: {
    color: palette.text.secondary,
    fontSize: '.875rem',
    fontFamily: fonts.bodyFamily
  },
  caption: {
    fontFamily: fonts.bodyFamily
  },
  button: {
    textTransform: 'none'
  }
};

const darkTypography = _.cloneDeep(typography);

darkTypography.display4.color = darkPalette.text.primary;
darkTypography.display3.color = darkPalette.text.primary;
darkTypography.display2.color = darkPalette.text.primary;
darkTypography.h1.color = darkPalette.text.primary;
darkTypography.h2.color = darkPalette.text.primary;
darkTypography.h3.color = darkPalette.text.primary;
darkTypography.h4.color = darkPalette.text.primary;

darkTypography.body1.color = darkPalette.text.secondary;

const props = {
  MuiButtonBase: {
    disableRipple: true
  }
};

const overrides = {
  MuiButton: {
    root: {
      borderRadius: 5,
      textTransform: 'none',
      fontWeight: 700,
      transition: 'none'
    },
    contained: {
      backgroundColor: '#ffffff',
      '&:hover': {
        backgroundColor: palette.grey[50]
      }
    },
    sizeLarge: {
      padding: '12px 24px',
      minHeight: 50,
      fontSize: '18px'
    },
    sizeSmall: {
      minWidth: '24px'
    }
  },
  MuiIconButton: {
    root: {
      color: palette.grey[700]
    }
  },
  MuiFab: {
    colorInherit: {
      backgroundColor: '#fff',
      '&:hover': {
        backgroundColor: palette.grey[50]
      }
    }
  },
  MuiCard: {
    root: {
      borderRadius: 5
    }
  },
  MuiPaper: {
    rounded: {
      borderRadius: 5
    }
  },
  MuiAppBar: {
    colorDefault: {
      backgroundColor: 'transparent',
      color: '#fff'
    },
    colorPrimary: {
      backgroundColor: 'transparent',
      color: palette.grey[900]
    },
    colorSecondary: {
      backgroundColor: palette.grey[50],
      color: palette.grey[900]
    }
  },
  MuiFormLabel: {
    root: {
      fontWeight: 500
    }
  },
  MuiFormControlLabel: {
    label: {
      fontWeight: 500
    }
  },
  MuiBadge: {
    badge: {
      fontWeight: 500
    }
  },
  MuiChip: {
    root: {
      backgroundColor: palette.grey[100],
      fontWeight: 'bold'
    }
  },
  MuiTooltip: {
    tooltip: {
      fontSize: '.875em'
    }
  },
  MuiMenuItem: {
    root: {
      marginTop: '2px'
    }
  },
  MuiTypography: {
    gutterBottom: {
      marginBottom: '0.5em'
    }
  },
  MuiTabs: {
    root: {
      textTransform: 'none'
    },
    scrollButtons: {
      width: 28
    }
  },
  MuiTab: {
    root: {
      maxWidth: 500,
      [defaultTheme.breakpoints.up('md')]: {
        minWidth: 0
      }
    },
    labelContainer: {
      [defaultTheme.breakpoints.up('md')]: {
        padding: '6px 12px'
      }
    }
  },
  MuiFilledInput: {
    root: {
      backgroundColor: 'rgba(0, 0, 0, 0.033)',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.066)'
      },
      '&$focused': {
        backgroundColor: 'rgba(0, 0, 0, 0.049)'
      },
      '&$disabled': {
        backgroundColor: 'rgba(0, 0, 0, 0.1)'
      }
    }
  }
};

const darkOverrides = _.cloneDeep(overrides);

darkOverrides.MuiChip.root.backgroundColor = palette.grey[700];
darkOverrides.MuiIconButton.root.color = 'white';

const shadows = [
  'none',
  '0 0px 6px 0 rgba(0,0,0,0.15)',
  '0 0px 7px 0 rgba(0,0,0,0.15)',
  '0 0px 8px 0 rgba(0,0,0,0.15)',
  '0 0px 9px 0 rgba(0,0,0,0.15)',
  '0 1px 10px 0 rgba(0,0,0,0.15)',
  '0 1px 11px 0 rgba(0,0,0,0.15)',
  '0 1px 12px 0 rgba(0,0,0,0.15)',
  '0 1px 13px 0 rgba(0,0,0,0.15)',
  '0 2px 14px 0 rgba(0,0,0,0.15)',
  '0 2px 15px 0 rgba(0,0,0,0.15)',
  '0 2px 16px 0 rgba(0,0,0,0.15)',
  '0 2px 17px 0 rgba(0,0,0,0.15)',
  '0 3px 18px 0 rgba(0,0,0,0.15)',
  '0 3px 19px 0 rgba(0,0,0,0.15)',
  '0 3px 20px 0 rgba(0,0,0,0.15)',
  '0 3px 21px 0 rgba(0,0,0,0.15)',
  '0 4px 22px 0 rgba(0,0,0,0.15)',
  '0 4px 23px 0 rgba(0,0,0,0.15)',
  '0 4px 24px 0 rgba(0,0,0,0.15)',
  '0 4px 25px 0 rgba(0,0,0,0.15)',
  '0 5px 26px 0 rgba(0,0,0,0.15)',
  '0 5px 27px 0 rgba(0,0,0,0.15)',
  '0 5px 28px 0 rgba(0,0,0,0.15)',
  '0 5px 29px 0 rgba(0,0,0,0.15)'
];

const lightTheme = createMuiTheme({
  props: props,
  palette: palette,
  typography: typography,
  overrides: overrides,
  shadows: shadows,
  fonts: fonts
});

const darkTheme = createMuiTheme({
  props: props,
  palette: darkPalette,
  typography: darkTypography,
  overrides: darkOverrides,
  shadows: shadows,
  fonts: fonts
});

export { lightTheme, darkTheme, palette, fonts, darkPalette };
