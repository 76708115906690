import actionComposer from 'utilities/actionComposer';
import { queryContentStackByUrl } from 'utilities/contentStack';

export const GET_PODCAST_LANDING_STACK = actionComposer(
  'GET_PODCAST_LANDING_STACK'
);
export const getPodcastLandingStack = () => ({
  type: GET_PODCAST_LANDING_STACK.type,
  payload: queryContentStackByUrl('podcast_landing', '/podcast')
});
