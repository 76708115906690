import _ from 'lodash';

// SKU format: GKO-{species}-000-{item_format_name}-{quantity}-0-{sample}
export function generateGKOSku(genomeSlug, quantity) {
  if (!genomeSlug || !quantity) return null;
  try {
    var sku = 'GKO-';
    sku += {
      homo_sapiens: 'HS1',
      mus_musculus: 'MM1'
    }[genomeSlug];
    sku += '-000-0-';
    sku += {
      1.5: '1.5n',
      3: '003n',
      5: '005n',
      10: '010n'
    }[quantity];
    sku += '-0-0';
    return sku;
  } catch (error) {
    return null;
  }
}

export function getAddonPricing(productPrices, options) {
  let prices = [];
  options.forEach(option => {
    prices[option.slug] = getLowestPriceBySlug(productPrices, option.slug);
  });
  return prices;
}

export function getLowestPriceBySlug(productPrices, slug, basePrice, options) {
  // calculate lowest flat price for item
  let lowestPrice;
  if (basePrice) {
    lowestPrice = basePrice;
  } else {
    let pricesForSlug = filterPrices(productPrices, {
      product_slug: slug,
      price_type: 'price',
      ...options
    });
    let sortedPrices = _.sortBy(pricesForSlug, ['price']);
    lowestPrice = sortedPrices.length ? sortedPrices[0].price : 0.0;
  }

  // calculate lowest percent off price for item
  let percentOffsForSlug = filterPrices(productPrices, {
    product_slug: slug,
    price_type: 'percent',
    ...options
  });
  let sortedPercentOffs = _.sortBy(percentOffsForSlug, ['price']);
  let highestPercentOff = sortedPercentOffs.length
    ? sortedPercentOffs[sortedPercentOffs.length - 1].price
    : 0.0;
  let lowestPercentOffPrice = highestPercentOff
    ? lowestPrice * (1 - 0.01 * highestPercentOff)
    : lowestPrice;

  // calculate lowest flat off price for item
  let fixedOffsForSlug = filterPrices(productPrices, {
    product_slug: slug,
    price_type: 'fixed',
    ...options
  });
  let sortedFixedOffs = _.sortBy(fixedOffsForSlug, ['price']);
  let highestFixedOff = sortedFixedOffs.length
    ? sortedFixedOffs[sortedFixedOffs.length - 1].price
    : 0.0;
  let lowestFixedOffPrice = highestFixedOff
    ? lowestPrice - highestFixedOff
    : lowestPrice;

  return Math.min(lowestPrice, lowestPercentOffPrice, lowestFixedOffPrice);
}

export function filterPrices(productPrices, prodConfig = {}) {
  const {
    product_slug,
    product_type,
    selected_yield,
    selected_modification,
    length,
    price_type
  } = prodConfig;

  const flatPricingInfo = _.flatten(Object.values(productPrices));

  let prices = _.filter(flatPricingInfo, option => {
    if (!_.isNil(product_slug)) {
      if (option['product_slug'] !== product_slug) {
        return false;
      }
    }
    if (!_.isNil(product_type)) {
      if (option['product_type'] !== product_type) {
        return false;
      }
      if (product_type === 'standalone') {
        return true;
      }
    }
    if (!_.isNil(price_type)) {
      if (option['price_type'] !== price_type) {
        return false;
      }
    }
    //find any matching yields
    if (!_.isNil(selected_yield)) {
      // make list of yield ids
      let optionYields = _.map(option.yield_options, 'id');
      // if no match to prodConfig, filter this `option` out
      if (!_.includes(optionYields, selected_yield.id)) {
        return false;
      }
    }
    //find any matching modifications
    if (!_.isNil(selected_modification)) {
      // make list of mod ids
      let optionModifications = _.map(option.modified_options, 'id');
      // if no match to prodConfig, filter this `option` out
      if (!_.includes(optionModifications, selected_modification.id)) {
        return false;
      }
    }
    // check if length is in the option's range
    if (!_.isNil(length)) {
      if (!_.inRange(length, option.min_length, option.max_length + 1)) {
        return false;
      }
    }
    // if you make it here, you have a match
    return true;
  });
  return prices;
}
