import config from 'init/config';
import { fRequest } from 'utilities/api';
import { indexToWellLocation } from './utilities';
import _ from 'lodash';

export const api_AddToBuckCart = odlProject => {
  //Add positions to wells
  const odlProjectWithPositions = _.cloneDeep(odlProject);

  const payload = {
    product_slug: odlProjectWithPositions.product_slug,
    project_name: odlProjectWithPositions.name,
    plate_spec: {
      format: odlProjectWithPositions.plateType.slug,
      quantity_per_well: odlProjectWithPositions.quantityPerWell
    },
    replicates: odlProjectWithPositions.replicates,
    species: odlProjectWithPositions.genome.commonName,
    plates: odlProjectWithPositions.plates.map(plate => {
      const wellsWithPositions = plate.wells.map((well, idx) => {
        if (well.value) {
          return {
            gene_label: well.value,
            gene_name: well.validatedName,
            ensembl_id: well.ensemblId,
            is_control: well.type === 'control',
            position: indexToWellLocation(
              idx,
              odlProjectWithPositions.plateType.wellRows
            )
          };
        } else {
          return null;
        }
      });
      return {
        name: null, // Custom plate names can be specified here in the future if needed
        wells: wellsWithPositions.filter(Boolean) // Remove empty wells
      };
    })
  };
  return fRequest(`${config.buckRootUrl}/api/order/add/`, {
    method: 'POST',
    data: [payload]
  });
};

export const api_GetODLGenes = species => {
  return fRequest(
    `${config.buckRootUrl}/api/products/odl/genes/?species=${species}`,
    {
      method: 'GET'
    }
  );
};

export const api_GetODLPrice = (quantityPerWell, replicates, totalNumGenes) => {
  return fRequest(
    `${config.buckRootUrl}/api/order/on-demand-libraries/pricing/?quantity_per_well=${quantityPerWell}&replicates=${replicates}&total_num_genes=${totalNumGenes}`,
    {
      method: 'GET'
    }
  );
};
