import _ from 'lodash';
import {
  GET_SGRNA_STACK,
  GET_SGRNA_CART_PROJECT,
  SET_SGRNA_PROJECT_DETAILS,
  SET_SGRNA_ACTIVE_PLATE,
  SET_SGRNA_ADD_DIALOG_METHOD,
  SET_SGRNA_PLATE_DIALOG_METHOD,
  RESET_SGRNA_PROJECT,
  ADD_SGRNA_EMPTY_PLATE,
  DELETE_SGRNA_PLATE,
  SET_SGRNA_PLATE_NEW,
  SET_SGRNA_PLATE_NAME,
  SET_SGRNA_PLATE_YIELD,
  SET_SGRNA_PLATE_MODS,
  ADD_SGRNA_SEQUENCES_TO_PLATE,
  UPDATE_SGRNA_PLATE_WELLS,
  UPDATE_SGRNA_WELL,
  ADD_SGRNA_EMPTY_TUBE,
  UPDATE_SGRNA_TUBE,
  DELETE_SGRNA_TUBE,
  ADD_SGRNA_SEQUENCES_TO_TUBES,
  ADD_SGRNA_PROJECT_TO_CART,
  CLEAR_SGRNA_CART_ERRORS,
  UPDATE_ADD_ONS,
  UPDATE_SGRNA_CART_PROJECT,
  UPDATE_SGRNA_PROJECT_DETAILS
} from './actions';

import { plateOptions } from './constants';
import { formatOrderItemForProject } from './utilities';

export const getInitialState = () => ({
  editMode: false,
  project: {
    productSlug: null,
    genome: null,
    cellLine: null,
    format: null,
    plates: [],
    plateType: plateOptions[0], // 96 pool plate is currently the only option,
    activePlate: 0,
    tubes: [],
    addOns: [],
    addDialogMethod: null,
    plateDialogMethod: { action: null, plateIdx: null }
  },
  stack: {},
  stackLoading: true,
  cartError: false,
  cartErrorMessage: null,
  cartLoading: false,
  cartProjectError: false,
  cartProjectErrorMessage: null,
  cartProjectLoading: false,
  redirectToCart: false,
  priceLoading: false
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case GET_SGRNA_STACK.PENDING:
      return {
        ...state,
        stackLoading: true
      };
    case GET_SGRNA_STACK.FULFILLED:
      return {
        ...state,
        stack: action.payload[0],
        stackLoading: false
      };
    case GET_SGRNA_STACK.REJECTED:
      return {
        ...state,
        stackLoading: false
      };
    case GET_SGRNA_CART_PROJECT.PENDING:
      return {
        ...state,
        cartProjectError: false,
        cartProjectLoading: true
      };
    case GET_SGRNA_CART_PROJECT.FULFILLED:
      return {
        ...state,
        editMode: true,
        project: {
          ...state.project,
          ...formatOrderItemForProject(action.payload, state.project)
        },
        cartProjectLoading: false
      };
    case GET_SGRNA_CART_PROJECT.REJECTED:
      return {
        ...state,
        cartProjectError: true,
        cartProjectLoading: false,
        cartProjectErrorMessage:
          action.payload.error_message?.[0] ||
          action.payload.error_message ||
          action.payload?.detail
      };
    case UPDATE_SGRNA_CART_PROJECT.PENDING:
      return {
        ...state,
        cartLoading: true,
        cartError: false
      };
    case UPDATE_SGRNA_CART_PROJECT.FULFILLED:
      return {
        ...state,
        cartLoading: false,
        cartError: false,
        redirectToCart: true
      };
    case UPDATE_SGRNA_CART_PROJECT.REJECTED:
      return {
        ...state,
        cartLoading: false,
        cartError: true,
        cartErrorMessage:
          action.payload.error_message?.[0] ||
          action.payload.error_message ||
          action.payload?.detail ||
          action.payload?.non_field_errors?.join(', ') ||
          JSON.stringify(action.payload)
      };
    case ADD_SGRNA_PROJECT_TO_CART.PENDING:
      return {
        ...state,
        cartLoading: true,
        cartError: false
      };
    case ADD_SGRNA_PROJECT_TO_CART.FULFILLED:
      return {
        ...state,
        cartLoading: false,
        cartError: false,
        redirectToCart: true
      };
    case ADD_SGRNA_PROJECT_TO_CART.REJECTED:
      return {
        ...state,
        cartLoading: false,
        cartError: true,
        cartErrorMessage:
          action.payload.error_message?.[0] ||
          action.payload.error_message ||
          action.payload?.detail ||
          action.payload?.non_field_errors?.join(', ') ||
          JSON.stringify(action.payload)
      };
    case CLEAR_SGRNA_CART_ERRORS:
      return {
        ...state,
        cartError: false,
        cartErrorMessage: null,
        redirectToCart: false
      };
    case SET_SGRNA_PROJECT_DETAILS:
      return {
        ...state,
        project: { ...state.project, ...action.newDetails }
      };
    case UPDATE_SGRNA_PROJECT_DETAILS:
      return {
        ...state,
        project: { ...state.project, ...action.newDetails }
      };
    case SET_SGRNA_ACTIVE_PLATE:
      return {
        ...state,
        project: { ...state.project, activePlate: action.plateIdx }
      };
    case SET_SGRNA_ADD_DIALOG_METHOD:
      return {
        ...state,
        project: {
          ...state.project,
          addDialogMethod: action.value
        }
      };
    case SET_SGRNA_PLATE_DIALOG_METHOD:
      return {
        ...state,
        project: {
          ...state.project,
          plateDialogMethod: { action: action.value, plateIdx: action.plateIdx }
        }
      };
    case RESET_SGRNA_PROJECT:
      return getInitialState();
    case ADD_SGRNA_EMPTY_PLATE:
      return {
        ...state,
        project: {
          ...state.project,
          plates: [...state.project.plates, action.plate]
        }
      };
    case DELETE_SGRNA_PLATE:
      return {
        ...state,
        project: {
          ...state.project,
          plates: [
            ...state.project.plates.slice(0, action.plateIdx),
            ...state.project.plates.slice(
              action.plateIdx + 1,
              state.project.plates.length
            )
          ]
        }
      };
    case SET_SGRNA_PLATE_NEW:
      return {
        ...state,
        project: {
          ...state.project,
          plates: Object.assign([...state.project.plates], {
            [action.plateIdx]: {
              ...state.project.plates[action.plateIdx],
              new: action.value
            }
          })
        }
      };
    case SET_SGRNA_PLATE_NAME:
      return {
        ...state,
        project: {
          ...state.project,
          plates: Object.assign([...state.project.plates], {
            [action.plateIdx]: {
              ...state.project.plates[action.plateIdx],
              name: action.value
            }
          })
        }
      };
    case SET_SGRNA_PLATE_YIELD:
      return {
        ...state,
        project: {
          ...state.project,
          plates: Object.assign([...state.project.plates], {
            [action.plateIdx]: {
              ...state.project.plates[action.plateIdx],
              yield: action.value
            }
          })
        }
      };
    case SET_SGRNA_PLATE_MODS:
      return {
        ...state,
        project: {
          ...state.project,
          plates: Object.assign([...state.project.plates], {
            [action.plateIdx]: {
              ...state.project.plates[action.plateIdx],
              modifications: action.value
            }
          })
        }
      };

    case ADD_SGRNA_SEQUENCES_TO_PLATE:
      return {
        ...state,
        project: {
          ...state.project,
          plates: Object.assign([...state.project.plates], {
            [action.meta.plateIdx]: {
              ...state.project.plates[action.meta.plateIdx],
              new: false,
              loading: false,
              name: action.meta.plateName,
              wells: Object.assign(
                [...state.project.plates[action.meta.plateIdx].wells],
                {
                  ...action.payload
                }
              )
            }
          })
        }
      };
    case UPDATE_SGRNA_PLATE_WELLS:
      return {
        ...state,
        project: {
          ...state.project,
          plates: Object.assign([...state.project.plates], {
            [action.plateIdx]: {
              ...state.project.plates[action.plateIdx],
              wells: action.newWells
            }
          })
        }
      };

    case UPDATE_SGRNA_WELL:
      return {
        ...state,
        project: {
          ...state.project,
          plates: Object.assign([...state.project.plates], {
            [action.plateIdx]: {
              ...state.project.plates[action.plateIdx],
              wells: Object.assign(
                [...state.project.plates[action.plateIdx].wells],
                {
                  [action.wellIdx]: {
                    ...state.project.plates[action.plateIdx].wells[
                      action.wellIdx
                    ],
                    ...action.newWell
                  }
                }
              )
            }
          })
        }
      };

    case ADD_SGRNA_EMPTY_TUBE:
      return {
        ...state,
        project: {
          ...state.project,
          tubes: [...state.project.tubes, action.tube]
        }
      };

    case UPDATE_SGRNA_TUBE:
      return {
        ...state,
        project: {
          ...state.project,
          tubes: Object.assign([...state.project.tubes], {
            [action.tubeIdx]: {
              ...state.project.tubes[action.tubeIdx],
              ...action.newTube
            }
          })
        }
      };

    case ADD_SGRNA_SEQUENCES_TO_TUBES:
      const overwriteFirstTube =
        state.project?.tubes?.length === 1 &&
        (!state.project?.tubes?.[0]?.sequence ||
          !state.project?.tubes?.[0]?.name);
      return {
        ...state,
        project: {
          ...state.project,
          tubes: overwriteFirstTube
            ? action.sequences
            : [...state.project.tubes, ...action.sequences]
        }
      };

    case DELETE_SGRNA_TUBE:
      return {
        ...state,
        project: {
          ...state.project,
          tubes: [
            ...state.project.tubes.slice(0, action.tubeIdx),
            ...state.project.tubes.slice(
              action.tubeIdx + 1,
              state.project.tubes.length
            )
          ]
        }
      };

    case UPDATE_ADD_ONS:
      const addOnIdx = _.findIndex(state.project.addOns, [
        'product_slug',
        action.newAddOn.product_slug
      ]);

      if (addOnIdx > -1) {
        return {
          ...state,
          project: {
            ...state.project,
            addOns:
              action.newQuantity > 0
                ? [
                    ...state.project.addOns.slice(0, addOnIdx),
                    { ...action.newAddOn, quantity: action.newQuantity },
                    ...state.project.addOns.slice(
                      addOnIdx + 1,
                      state.project.addOns.length
                    )
                  ]
                : [
                    ...state.project.addOns.slice(0, addOnIdx),
                    ...state.project.addOns.slice(
                      addOnIdx + 1,
                      state.project.addOns.length
                    )
                  ]
          }
        };
      } else {
        return {
          ...state,
          project: {
            ...state.project,
            addOns: [
              ...state.project.addOns,
              { ...action.newAddOn, quantity: action.newQuantity }
            ]
          }
        };
      }

    default:
      return state;
  }
};
