import { GET_PODCAST_LANDING_STACK } from './actions';

export const getInitialState = () => ({
  stack: {}
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case GET_PODCAST_LANDING_STACK.FULFILLED:
      return {
        ...state,
        stack: action.payload,
        loading: false
      };
    default:
      return state;
  }
};
