import { GET_PRODUCT_PRICING, GET_SKU_PRICING } from './actions';

export const getInitialState = () => ({
  productPricing: {},
  skuPricing: {},
  skuLoading: false,
  productLoading: false,
  pricingLoading: false
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case GET_SKU_PRICING.PENDING:
      return {
        ...state,
        skuLoading: true,
        pricingLoading: true
      };
    case GET_SKU_PRICING.FULFILLED:
      return {
        ...state,
        skuPricing: action.payload,
        skuLoading: false,
        pricingLoading: state.skuLoading && state.productLoading
      };
    case GET_SKU_PRICING.REJECTED:
      return {
        ...state,
        skuLoading: false,
        pricingLoading: state.skuLoading && state.productLoading
      };
    case GET_PRODUCT_PRICING.PENDING:
      return {
        ...state,
        productLoading: true,
        pricingLoading: true
      };
    case GET_PRODUCT_PRICING.FULFILLED:
      return {
        ...state,
        productPricing: action.payload,
        productLoading: false,
        pricingLoading: state.skuLoading && state.productLoading
      };
    case GET_PRODUCT_PRICING.REJECTED:
      return {
        ...state,
        productLoading: false,
        pricingLoading: state.skuLoading && state.productLoading
      };
    default:
      return state;
  }
};
