import { GET_HOME_STACK } from './actions';

export const getInitialState = () => ({
  stack: {},
  loading: true
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case GET_HOME_STACK.PENDING:
      return {
        ...state,
        loading: true
      };
    case GET_HOME_STACK.FULFILLED:
      return {
        ...state,
        loading: false,
        stack: action.payload[0]
      };
    case GET_HOME_STACK.REJECTED:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};
