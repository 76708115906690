import _ from 'lodash';
import dayjs from 'dayjs';
import shortid from 'shortid';
import {
  controlOptions,
  orderRequirements,
  ERRORS,
  WARNINGS
} from './constants';

//Select wells that are filled on a plate
export const selectFilledPlateWells = plate =>
  _.filter(plate.wells, well => !_.isEmpty(well?.value));

//Select all wells on a plate that have any warnings
export const selectWarningPlateWells = plate =>
  _.filter(plate.wells, well => well.warnings?.length);

//Select all wells on a plate that have any errors
export const selectErrorPlateWells = plate =>
  _.filter(plate.wells, well => well.errors?.length);

//Select all wells on a plate that have a GENE_NOT_FOUND error
export const selectGeneNotFoundPlateWells = plate =>
  _.filter(plate.wells, well => well.errors?.includes(ERRORS.GENE_NOT_FOUND));

//Select all wells on a plate that have a ZERO_GUIDES error
export const selectZeroGuidesPlateWells = plate =>
  _.filter(plate.wells, well => well.errors?.includes(ERRORS.ZERO_GUIDES));

//Select all wells on a plate with a FEWER_THAN_THREE_GUIDES warning
export const selectFewerThanThreeGuidesPlateWells = plate =>
  _.filter(plate.wells, well =>
    well.warnings?.includes(WARNINGS.FEWER_THAN_THREE_GUIDES)
  );

//Select all filled wells on the project and flatten to a single array
export const selectFlatFilledProjectWells = project =>
  _.flatten(_.map(project.plates, plate => selectFilledPlateWells(plate)));

//Select all filled wells on the project keeping them grouped by plate
export const selectFilledProjectWells = project =>
  _.map(project.plates, plate => selectFilledPlateWells(plate));

//Select all wells with warnings on the project keeping them grouped by plate
export const selectWarningProjectWells = project =>
  _.map(project.plates, plate => selectWarningPlateWells(plate));

//Select all invalid wells on the project keeping them grouped by plate
export const selectErrorProjectWells = project =>
  _.map(project.plates, plate => selectErrorPlateWells(plate));

//Select the indexes of all plates that don't have the minimum amount of wells filled
export const selectInvalidWellCountPlates = project =>
  _.reduce(
    project.plates,
    (result, plate, idx) => {
      if (
        selectFilledPlateWells(plate).length < orderRequirements.minPlateWells
      ) {
        result.push({
          plateIdx: idx
        });
      }
      return result;
    },
    []
  );

//Select the indexes and well objects of all invalid wells on the project
export const selectGeneNotFoundWellPlates = project =>
  _.reduce(
    _.cloneDeep(project.plates),
    (result, plate, idx) => {
      //Store the index of the invalid well so we can show it's location to the user
      plate.wells = plate.wells.map((w, idx) => ({ ...w, index: idx }));
      const invalidWells = selectGeneNotFoundPlateWells(plate);
      if (invalidWells.length) {
        result.push({
          plateIdx: idx,
          wells: invalidWells
        });
      }
      return result;
    },
    []
  );

//Select the indexes and well objects of all wells that have zero guides
export const selectZeroGuidesWellPlates = project =>
  _.reduce(
    _.cloneDeep(project.plates),
    (result, plate, idx) => {
      //Store the index of the invalid well so we can show it's location to the user
      plate.wells = plate.wells.map((w, idx) => ({ ...w, index: idx }));
      const invalidWells = selectZeroGuidesPlateWells(plate);
      if (invalidWells.length) {
        result.push({
          plateIdx: idx,
          wells: invalidWells
        });
      }
      return result;
    },
    []
  );

//Select the indexes and well objects of all wells that more than zero but fewer than three guides
export const selectFewerThanThreeGuidesWellPlates = project =>
  _.reduce(
    _.cloneDeep(project.plates),
    (result, plate, idx) => {
      //Store the index of the invalid well so we can show it's location to the user
      plate.wells = plate.wells.map((w, idx) => ({ ...w, index: idx }));
      const invalidWells = selectFewerThanThreeGuidesPlateWells(plate);
      if (invalidWells.length) {
        result.push({
          plateIdx: idx,
          wells: invalidWells
        });
      }
      return result;
    },
    []
  );

//Select genes on the project that are over the maximum pmol allowed
export const selectInvalidVolumeGenes = project => {
  const allWells = selectFlatFilledProjectWells(project);
  //Only select wells that are genes (not controls) and valid
  const allGenes = _.filter(
    allWells,
    well => well.type === 'gene' && !well.errors?.length
  );
  const geneCounts = _.map(_.groupBy(allGenes, 'value'), (items, value) => {
    return {
      value,
      count: items.length,
      pmolTotal:
        //Take into account copies of the project when calculating total volume
        items.length * project.quantityPerWell * (project.replicates + 1)
    };
  });
  const overflowGenes = _.filter(
    geneCounts,
    gene => gene.pmolTotal > orderRequirements.maxProjectGenePmol
  );

  return overflowGenes;
};

//Swap the position of two items in an array (used for drag and drop)
export const arraySwap = (array, from, to) => {
  let newArray = array.slice();
  var temp = newArray[from];
  newArray[from] = newArray[to];
  newArray[to] = temp;

  return newArray;
};

//Given a plate array, rearrange it from a vertical to horizontal orientation and vice-versa
export const arrayFlipDirection = (array, plateType) => {
  const { wellRows, wellColumns } = plateType;
  let newArray = _.range(0, wellRows * wellColumns).fill('');
  for (let i = 0; i < array.length; i++) {
    newArray[
      (i - wellColumns * Math.floor(i / wellColumns)) * wellRows +
        Math.floor(i / wellColumns)
    ] = array[i];
  }

  return newArray;
};

//Given an array index, calulate it's well position based on the number of rows on the plate
export const indexToWellLocation = (index, rows) => {
  const row = String.fromCharCode(
    65 + (index - rows * Math.floor(index / rows))
  );
  const column = Math.floor(index / rows) + 1;
  return `${row}${column}`;
};

//Format a gene symbol's capitalization based on genome
export const formatGeneSymbol = (symbol, genome) => {
  //If human uppercase all
  if (genome === 'human') {
    return symbol.toUpperCase();
    //If mouse capitalize only if symbol doesn't start with number or is 'a' (a valid mouse gene symbol)
  } else if (
    genome === 'mouse' &&
    !isFinite(symbol.charAt(0)) &&
    symbol !== 'a'
  ) {
    return _.capitalize(symbol);
  } else {
    return symbol;
  }
};

export const getGuideWarnings = numGuides => {
  let warnings = [];
  if (numGuides > 0 && numGuides < 3) {
    warnings.push(WARNINGS.FEWER_THAN_THREE_GUIDES);
  }
  return warnings;
};

export const getGuideErrors = numGuides => {
  let errors = [];
  if (numGuides === 0) {
    errors.push(ERRORS.ZERO_GUIDES);
  }
  return errors;
};

//Convert an array of gene symbols to an array of well objects, validating the genes
export const geneArrayToWells = async (
  genes,
  validGenes,
  wellDirection,
  project
) => {
  const { plateType, genome } = project;
  let wellsArray = [];

  for (let i = 0; i < plateType.wellCount; i++) {
    //If the symbol is in the control options, mark it as a control
    const control = _.find(controlOptions, ['label', genes[i]]);
    const wellType = Boolean(control) ? 'control' : genes[i] ? 'gene' : null;

    let formattedGene = '';
    let validatedName = '';
    let validatedGene = null;
    let warnings = [];
    let errors = [];
    let numGuides = null;
    let ensemblId = null;

    //Check the valid gene list to see if the gene is valid or not
    if (wellType === 'gene') {
      formattedGene = formatGeneSymbol(genes[i], project.genome.commonName);
      validatedName = formattedGene;
      if (!(formattedGene in validGenes)) {
        errors.push(ERRORS.GENE_NOT_FOUND);
      } else {
        validatedGene = validGenes[formattedGene];
        numGuides = validatedGene.num_guides;
        ensemblId = validatedGene.ensembl_id;
        errors.push(...getGuideErrors(numGuides));
        warnings.push(...getGuideWarnings(numGuides));
      }
    }
    //Check that the control is allowed for the project genome
    if (wellType === 'control') {
      formattedGene = genes[i];
      validatedName = control.validatedName;
      if (!control.genomes.includes(genome.slug)) {
        errors.push(ERRORS.GENE_NOT_FOUND);
      }
    }
    wellsArray[i] = {
      value: formattedGene,
      validatedName: validatedName,
      ensemblId: ensemblId,
      id: `well-${shortid.generate()}`,
      type: wellType,
      errors: errors,
      warnings: warnings,
      numGuides: numGuides
    };
  }
  if (wellDirection === 'horizontal') {
    return arrayFlipDirection(wellsArray, plateType);
  } else {
    return wellsArray;
  }
};

//Convert a list of gene symbols separated by commas or line breaks into an array of plate arrays
export const geneListToPlateArray = (geneList, wellCount) => {
  const geneArray = geneList.length
    ? geneList.replace(/\r?\n/g, ',').split(',')
    : [];
  const trimmedGeneArray = geneArray.map(g => g.trimStart().trimEnd());
  return _.chunk(trimmedGeneArray, wellCount);
};

//Convert a vertical list csv template
export const verticalGeneCsvToArray = (geneText, plateType) => {
  let geneArray;
  let rows = geneText.split('\n');
  rows.shift(); //Remove header
  geneArray = rows.map(row =>
    row
      .split(',')
      .slice(1, 2) //Remove first column
      .join()
      .replace(/['"\r]+/g, '')
  );
  //Trim to either the length of the array or the plate size, whichever is smaller
  geneArray.length = Math.min(geneArray.length, plateType.wellCount);
  return geneArray;
};

//Convert a 2d grid csv template to an array
export const gridGeneCsvToArray = (geneText, plateType) => {
  let rows = geneText.split('\n');
  rows.shift(); //Remove header
  //Fill and/or trim the rows based on the plate size
  rows = _.assign(_.fill(new Array(plateType.wellRows), ''), rows);
  rows.length = plateType.wellRows;
  let geneArray = rows.map(row => {
    row = row.replace(/['"\r]+/g, '').split(',');
    row.shift(); //Remove first column
    //Fill and/or trim the columns based on the plate size
    row = _.assign(_.fill(new Array(plateType.wellColumns), ''), row);
    row.length = plateType.wellColumns;
    return row;
  });
  return arrayFlipDirection(_.flatten(geneArray), plateType);
};

//Given csv text, determine which template type is being used
export const getTemplateType = geneText => {
  let rows = geneText.split('\n');
  if (rows.length) {
    rows = rows.map(cell => cell.replace(/['"\r]+/g, '').split(','));
    //Template has headers for vertical list layout
    if (rows[0][0] === 'Well Position' && rows[0][1] === 'Gene') {
      //Template is for 96 wells
      if (rows[9][0] === 'A2') {
        return 'vertical-96';
        //Template is for 384 wells
      } else if (rows[17][0] === 'A2') {
        return 'vertical-384';
      } else {
        return false;
      }
      //Template has header and first row for grid layout
    } else if (rows[0][1] === '1' && rows[1][0] === 'A') {
      return 'grid';
      //Otherwise, not a valid template
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const isValidTemplate = (geneText, plateType) => {
  const templateType = getTemplateType(geneText);

  if (templateType) {
    if (templateType !== 'grid') {
      if (plateType?.wellCount === 384 && templateType !== 'vertical-384') {
        return false;
      }
      if (plateType?.wellCount === 96 && templateType !== 'vertical-96') {
        return false;
      }
      return true;
    }
    return true;
  } else {
    return false;
  }
};

//Pick the proper conversion and return it based on the csv template provided
export const geneCsvToArray = (geneText, plateType) => {
  const templateType = getTemplateType(geneText);
  if (templateType === 'vertical-96' || templateType === 'vertical-384') {
    return verticalGeneCsvToArray(geneText, plateType);
  }
  if (templateType === 'grid') {
    return gridGeneCsvToArray(geneText, plateType);
  }
};

//Convert an entire project into an .xlsx workbook in a vertical list layout
export const downloadVerticalProject = async project => {
  const XLSX = await import('xlsx');
  const { plateType, plates, name } = project;
  let workbook = XLSX.utils.book_new();

  plates.forEach((plate, plateIdx) => {
    const plateArray = [['Well Position', 'Gene']];
    plate.wells.forEach((well, wellIdx) => {
      plateArray.push([
        indexToWellLocation(wellIdx, plateType.wellRows),
        well.value
      ]);
    });
    let sheet = XLSX.utils.aoa_to_sheet(plateArray);
    XLSX.utils.book_append_sheet(workbook, sheet, `Plate #${plateIdx + 1}`);
  });
  XLSX.writeFile(
    workbook,
    `${name}-synthego-${plateType.wellCount}-well-list-${dayjs().format(
      `YYYY-MM-DDTHHmmss`
    )}.xlsx`
  );
};

//Convert an entire project into an .xlsx workbook in a grid layout
export const downloadGridProject = async project => {
  const XLSX = await import('xlsx');
  const { plateType, plates, name } = project;
  let workbook = XLSX.utils.book_new();

  plates.forEach((plate, plateIdx) => {
    const plateArray = [_.range(project.plateType.wellColumns + 1)];
    plateArray[0][0] = '';
    for (let r = 0; r < plateType.wellRows; r++) {
      let row = [String.fromCharCode(65 + r)];
      for (let c = 0; c < plateType.wellColumns; c++) {
        row.push(plate.wells[r + c * plateType.wellRows]?.value);
      }
      plateArray.push(row);
    }
    let sheet = XLSX.utils.aoa_to_sheet(plateArray);
    XLSX.utils.book_append_sheet(workbook, sheet, `Plate #${plateIdx + 1}`);
  });
  XLSX.writeFile(
    workbook,
    `${name}-synthego-${plateType.wellCount}-well-grid-${dayjs().format(
      `YYYY-MM-DDTHHmmss`
    )}.xlsx`
  );
};
